import styled from 'styled-components'
import { H2 } from '../components/Generics'
import {
  AnalyzeCardWithImage,
  Card,
  DirectCostAccessCard,
  NoAdditionalFeesCard,
  SwapTokensQuicklyNoFees,
} from '../components/blocks/CardWithHeader'

const SectionLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0;
  max-width: 93vw;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.xxm}px`}) {
    padding: 0 48px;
  }
  @media (max-width: 468px) {
    padding: 0 8px;
  }
`

const SectionCol = styled.div`
  flex-direction: column;
  max-width: 93vw;
  gap: 40px;
  width: 100%;

  @media (max-width: ${({ theme }) => `${theme.breakpoint.xxm}px`}) {
    gap: 24px;
  }
`
// const RowToCol = styled.div`
//   height: auto;
//   width: 100%;
//   flex-shrink: 1;
//   display: flex;
//   flex-direction: column;
//   max-width: 93vw;

//   gap: 14px;
// `
const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  @media (max-width: ${({ theme }) => `${theme.breakpoint.xxm}px`}) {
    flex-direction: column;
  }
`
const Title = styled(H2)`
  width: 100%;
  text-align: center;
  margin: 0;
  margin-bottom: 40px;
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 52px;
  font-family: Inter;

  @media (max-width: ${({ theme }) => `${theme.breakpoint.xxm}px`}) {
    text-align: left;
    margin-bottom: 24px;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
  }
`

const GridContainer = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 16px;
  grid-template-columns: 1fr;
  grid-template-rows: auto 374px 120px 120px 374px;
  grid-template-areas:
    'swap'
    'analyze'
    'direct'
    'fees'
    'earn';

  @media (min-width: ${({ theme }) => `${theme.breakpoint.md}px`}) {
    grid-template-columns: 3fr 3fr 3fr 3fr 4fr;
    grid-template-rows: auto auto;
    grid-template-areas:
      'analyze analyze analyze swap swap'
      'analyze analyze analyze swap swap'
      'fees fees earn earn earn'
      'direct direct earn earn earn';
  }
`

const SmallAreaNoFees = styled.div`
  grid-area: fees;
  display: flex;
`

const SmallAreaDirectCostAccess = styled.div`
  grid-area: direct;
  display: flex;
`

const BigAreaAnalyze = styled.div`
  grid-area: analyze;
`

const BigAreaSwapTokensQuickly = styled.div`
  grid-area: swap;
  height: 100%;
`

const BigAreaEarn = styled.div`
  grid-area: earn;
`

const SingleEcosystem = () => {
  return (
    <SectionLayout>
      <SectionCol>
        <Title>All your desires in a single ecosystem</Title>
        <FlexContainer style={{ flexDirection: 'column' }}>
          <GridContainer>
            <BigAreaAnalyze>
              <AnalyzeCardWithImage />
            </BigAreaAnalyze>

            <BigAreaSwapTokensQuickly>
              <SwapTokensQuicklyNoFees />
            </BigAreaSwapTokensQuickly>

            <SmallAreaNoFees>
              <NoAdditionalFeesCard />
            </SmallAreaNoFees>

            <BigAreaEarn>
              <ProvideLiquidityInPool />
            </BigAreaEarn>

            <SmallAreaDirectCostAccess>
              <DirectCostAccessCard />
            </SmallAreaDirectCostAccess>
          </GridContainer>
        </FlexContainer>
        {/* <hr /> */}
        {/* <hr /> */}
        {/* <FlexContainer style={{ width: '100%' }}>
          <RowToCol>
            <CardWithHeader
              img={<ThirdIcon />}
              title={
                <>
                  Direct <span>cost access</span>
                </>
              }
              card={{
                title: 'Analyse',
                desc: (
                  <>
                    Market with up-to-date <span>Analytics</span>
                  </>
                ),
                bg_img: '/images/landing/bloks/1.webp',
              }}
            />
            <GasOptimized />
          </RowToCol>
          <RowToCol>
            <Card
              height="350px"
              title="Earn"
              bg_img="/images/landing/bloks/2.webp"
              desc={
                <>
                  Providing liquidity in our <span>Pool</span>
                </>
              }
            />
            <CardWithHeader
              img={<SecondIcon />}
              title={
                <>
                  No addictional <span>fees</span>
                </>
              }
              card={{
                title: 'Trade',
                desc: (
                  <>
                    With a user-friendly decentralized <span>Swap</span>
                  </>
                ),
                bg_img: '/images/landing/bloks/4.webp',
                height: '329px',
              }}
            />
          </RowToCol>
        </FlexContainer> */}
      </SectionCol>
    </SectionLayout>
  )
}

// const SwapTokensQuicklyNoFees = () => {
//   return (
//     <Card
//       height="420px"
//       title="Swap tokens quickly with low fees"
//       bg_img="/images/landing/bloks/3.webp"
//       desc={<> </>}
//     />
//   )
// }

const ProvideLiquidityInPool = () => {
  return (
    <Card
      height="420px"
      title="Earn"
      // bg_img="/images/landing/bloks/2.webp"
      bg_img="/images/landing/bloks/2_2.png"
      desc={
        <>
          Providing liquidity in our <span>Pool</span>
        </>
      }
    />
  )
}

export default SingleEcosystem
